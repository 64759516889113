import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import URLs from "../../../static/data/urls.json";
import CampaignLogo from "../../images/campaign-theme/campaign-logo-no-sponsor.webp";
import ExternalLink from "../../components/external-link";
import * as styles from "./footer.module.scss";

const Footer = () => {
  // get url from config
  const { site } = useStaticQuery(
    graphql`
      query FooterInfo {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  const { title, description } = site.siteMetadata;
  const { buy_toys_url, donate_cash_url, drop_off_url } = URLs;

  return (
    <div className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Link to={"/"}>
            <img src={CampaignLogo} alt={title} />
          </Link>
        </div>
        <div className={styles.text}>
          <h1>Thank you for supporting the {title}</h1>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.callToAction}>
 
          <ExternalLink href={donate_cash_url}>
            Donate money online
          </ExternalLink>
          <Link to={drop_off_url}>
            Find your nearest Toy Drop
          </Link>
        </div>
        <div className={styles.copyright}>
          <p>
            &copy;{new Date().getFullYear()} KGW Portland &bull; A TEGNA Company
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
