import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { isIE } from "react-device-detect";
import Favicons from "./favicons";
import Footer from "./footer";
import Header from "./header";
import IESection from "./ie.js";
import "./layout.scss";

const Layout = ({ children, home }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const { title } = site.siteMetadata;

  return (
    <>
      <Favicons />
      <div id="main"    >
        <Header home={home || false} siteTitle={title} />
        <div>
          {!isIE && <main>{children}</main>}
          {isIE && <IESection />}

          <Footer />
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
